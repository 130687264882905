<template>
  <div>
    <DialogPop
      width="600px"
      title=""
      :sureText="sureText"
      :visible="visible"
      :header="false"
      :closeBtn="false"
      :btnloading="btnloading"
      @onClose="onClose"
      @onSure="onSure('form')"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="first">
          <span slot="label" class="disal mb10">
            <img
              width="25" :src="activeName=='first'?require('@/assets/imgs/ico_jiami_buy_p@2x.png'):require('@/assets/imgs/ico_jiami_buy_d@2x.png')" class="mr10" />
            {{ translateTitle("买") }}
          </span>
        </el-tab-pane>
        <el-tab-pane name="second">
          <span slot="label" class="disal">
            <img
              width="25"
              :src="activeName=='second'?require('@/assets/imgs/ico_jiami_sell_p@2x.png'):require('@/assets/imgs/ico_jiami_sell_d@2x.png')"
              class="mr10"
            />
            {{ translateTitle("卖") }}
          </span>
        </el-tab-pane>
        <div></div>
      </el-tabs>

      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <div class="change-form">
          <div class="buy-title tc">
            {{
              activeName == "first"
                ? translateTitle("请输入金额")
                : translateTitle("请输入金额")
            }}
          </div>

          <el-form-item label="" prop="coinCount" class="buy-form">
            <el-input
              v-model="form.coinCount"
              clearable
              placeholder="0"
              class="buy-input"
              @input="changeAmount"
            ></el-input>
            <!-- <el-input-number class="buy-input" v-model="form.coinCount" placeholder="0" :precision="2" :controls="false"></el-input-number> -->
            
          </el-form-item>
          <div class="buy-coinName">{{activeName == "first"?'USDT': form.coinName}}</div>
        </div>

        <div class="change-form mt20">
          <div class="buy-title mb15">
            {{
              activeName == "first"
                ? translateTitle("买")
                : translateTitle("卖")
            }}
          </div>
          <el-form-item prop="coinName">
            <el-select
              class="svg-container"
              clearable
              v-model="form.coinName"
              :placeholder="translateTitle('请选择')"
              @change="changeCoinName(form.coinName)"
            >
              <template #prefix>
                <span class="flex">
                  <img
                    :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_btc.png')"
                    width="24"
                    height="24"
                  />
                </span>
              </template>
              <el-option
                v-for="item in option"
                :key="item.id"
                :label="item.coinName"
                :value="item.id"
              >
                <div class="disal">
                  <img
                    :src="item.coinLogo"
                    width="24"
                    height="24"
                  />
                  <span class="select-item">{{ item.coinName }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="form.coinName" class="mb15">
            <span class="account-balance">{{ form.coinName }}{{translateTitle('价格')}}</span>
            <span class="account-num"
              >1{{ form.coinName }} ＝{{ to_usdt_rate }} USDT</span
            >
          </div>
          <el-form-item :label="translateTitle('到账账户')" prop="coinLink">
            <el-input
              disabled
              v-model="userInfo.id"
              :placeholder="translateTitle('到账账户')"
            />
          </el-form-item>
          <div class="mb15">
              <span class="account-balance">{{translateTitle('账户余额')}}</span>
              <span class="account-num">&nbsp;{{ balance }} {{activeName == 'first'?isName :form.coinName}}</span>
            </div>
            <div v-if="form.coinCount" class="mb15 account-num1">
              <span>{{translateTitle('手续费')}}</span>
              <span>&nbsp;{{ transferAmount }} {{activeName == 'first'?'USDT' :form.coinName}}</span>
            </div>
            <!-- <div v-if="form.coinCount" class="mb15 account-num1">
              <span>{{translateTitle('实际到账')}}</span>
              <span>&nbsp;{{ subtr(form.coinCount, transferAmount) }} USDT</span>
            </div> -->
          <!-- <el-form-item :label="translateTitle('数量')" prop="coinLink">
            <el-input
              v-model="coinCountUsdt"
              :placeholder="translateTitle('请输入')"
            />
          </el-form-item> -->

          
        </div>
      </el-form>
    </DialogPop>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { coinNames, coinLink, coinBalanceUSD, getTransferRate, calculateCharge } from "@/api/index.js";
import { buyCoin, saleCoin } from "@/api/wallet.js";
import { translateTitle } from "@/utils/i18n";
import {multiply,subtr} from '@/utils/date'
export default {
  name: "buyDiaolg",
  model: {
    prop: "visible",
    event: "visibleEvent",
  },

  props: {
    // 显示/隐藏
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      const num= /(^[0-9]\d*(\.\d{1,100})?$)|(^0(\.\d{1,100})?$)/;
      if (!num.test(value)) {
        callback(new Error(this.translateTitle("请输入正确格式")))
      }else{
        callback()
      }
    }
    return {
      option: [],
      CoinLinkOption: [],
      to_usdt_rate: "0",
      isName: '',
      choiceCoin: {
        coinName: '',
        coinLogo: "",
      },
      form: {
        coinCount: "",
        coinName: "",
        coinLink: "",
      },
      rules: {
        coinCount: [{ required: true, message:this.translateTitle("请输入金额"), trigger: "change" },
        { required: true, validator: validateAmount, trigger: "change" }],
        coinName: [
          { required: true, message:this.translateTitle("请选择币种"), trigger: "change" },
        ],
      },
      value: "",
      input: "",
      activeName: "first",
      sureText: this.translateTitle("买"),
      btnloading: false,
      coinCountUsdt: '',
      globalLoading: null,
      balance: "0",
      transferRate: '0',
      transferAmount: '0',
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
    }),
  },
  watch: {
    // 'form.coinCount': function (params) {
    //   if (params == '') { this.coinCountUsdt = 0; } else { this.coinCountUsdt = this.to_usdt_rate*params; }
    // },
    // 'coinCountUsdt': function (params) {
    //   if (params == '') { this.form.coinCount = 0; } else { this.form.coinCount = params/this.to_usdt_rate; }
    // },
  },
  created() {
    this.getCoinName();
    this.getcoinBalance()
  },
  methods: {
    multiply,
    subtr,
    translateTitle,
    changeAmount(val) {
      console.log(12312)
     if (val == '') { this.transferAmount = 0; } else {
       this.getTransferAmount(val)
        //  this.transferAmount = this.multiply(this.transferRate,params);
          }
    },
    getTransferAmount(val) {
      let params = {
         coinLink: this.form.coinLink,
         coinName: this.form.coinName,
         inputAmount: val || 0,
         type: this.activeName == 'first' ? 3 : 2,
       }
       calculateCharge(params).then(res => {
          this.transferAmount = res.data.charge
       })
    },
    async getCoinName() {
      let res = await coinNames();
      this.option = res.data;
    },
    async getCoinLink(val) {
      let res = await coinLink(val);
      this.CoinLinkOption = res.data;
    },
    getcoinBalance(val) {
      let params = {
        coinName:val? val.coinName : 'a',
        coinLink:val? val.coinLink : 'b',
        sysCoin: this.activeName == 'first' ? true : '',
        selfCoin: this.activeName == 'first' ? true : '',
      };
      coinBalanceUSD(params).then((res) => {
        if(res.code == 200) {
            this.to_usdt_rate = res.data.to_usdt_rate;
        this.balance = res.data.balance;
        this.isName = res.data.coinName;
          } else {
            this.$message.error(this.translateTitle(res.msg));
          }
      });
      let params1 = {
        type: this.activeName == 'first' ? '3' : '2'
      }
      // getTransferRate({...params, ...params1}).then(res => {
      //   this.transferRate = res.data.rate
      // })
    },
    changeCoinName(data) {
      let obj  = this.option.find(item => item.id === data)
      console.log(obj)

      this.choiceCoin.coinName = obj.coinName;
      this.choiceCoin.coinLogo = obj.coinLogo;
      this.form.coinLink = obj.coinLink;
      this.form.coinName = obj.coinName
      // this.getCoinLink(this.form.coinName);
       this.getTransferAmount(this.form.coinCount)
      if(this.activeName == "second"){
        this.getcoinBalance(obj);
      }
      
    },
    // changeCoinLink() {
    //   this.getcoinBalance();
    // },

    onSure(formName) {
      let _request;
      if (this.activeName == "first") {
        _request = buyCoin;
      } else {
        _request = saleCoin;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.btnloading = true
           this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
          _request(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.translateTitle("操作成功"));
              this.$refs["form"].resetFields();
              this.$emit("visibleEvent", false);
            } else {
              this.$message.error(this.translateTitle(res.msg));
            }
          }).finally(() => {
            // this.btnloading = false
            this.globalLoading.close()
          });
        } else {
          return false;
        }
      });
    },
    // 取消
    onClose() {
      this.$emit("visibleEvent", false);
    },
    handleClick() {
      this.form.coinCount = ''
      this.form.coinName = ''
      this.form.coinLink = ''
      this.balance = 0
      if (this.activeName == "first") {
        this.sureText =this.translateTitle("买") ;
        this.getcoinBalance()
      } else if (this.activeName == "second") {
        this.sureText =this.translateTitle("卖") ;
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-tabs__item {
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #6F7D8D;
  }
  .el-tabs__item.is-active {
    color: #31363e;
  }
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__active-bar {
    width: 76px;
    height: 4px;
    background: #786234;
  }

  .el-dialog__body {
    padding: 10px 20px;
  }
}
.change-form {
  .buy-title {
    color: rgba(49,54,62,0.6);
  }
  .el-form-item.buy-form {
    width: 56%;
    display: inline-block;
    margin-bottom: 0px;
  }
  .buy-coinName {
    display: inline-block;
  }
  .buy-input {
    font-size: 48px;
    width: 100%;
    font-weight: bold;
    color: rgba(49,54,62,0.6);
    text-align: center;

    ::v-deep {
      .el-input__inner {
        background-color: #F8FAFB !important;
        text-align: right;
      }
    }
  }
  .buy-input.el-input-number {
   ::v-deep { .el-input {
      font-size: 48px;
    }}
  }
  .account-balance {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    margin-right: 5px;
    color: #31363E;
  }
  .account-num {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #31363E;
  }
  .account-num1 {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(49,54,62,0.6);
  }
}
.select-item {
  margin-left: 10px;
}
</style>
