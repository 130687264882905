<template>
	<div>
		<el-card class="box-card">
			<div class="title">
				<!-- {{ translateTitle("账户") }}s -->
				<Header />
				<div class="account-content">
					<div>
						<div class="disal">
							<div class="mr10 all-amount">
								{{ translateTitle("你的总余额预估") }}
							</div>
							
						</div>
						<div class="all-num">≈
							<p style="font-size: 32px;
							font-weight: bold;">{{ balanceCoin }}</p>
									<span>USD</span>
						</div>
					</div>
					
					<div class="btn" @click="handleClickBtn('recharge')">
						{{ translateTitle("充值银行账户") }}&nbsp;&nbsp;<i class="el-icon-right"></i>
					</div>
				</div>
			</div>
			<!-- <Headers /> -->
			<div class="top-header">
				<div class="disal header-left">
					<img :src="headerImg" />
					<div class="ml20">
						<div class="header-left-title">
							{{ translateTitle("加密货币资产") }}
						</div>
						<!-- <div class="header-left-num">{{ balanceCoin }}<span class="ml5">USDT</span></div> -->
					</div>
				</div>
				<div class="btn-list">
					<el-button @click="handleClickBtn('add')">{{
            translateTitle("添加钱包")
          }}</el-button>
					<!-- <el-button
						@click="handleClickBtn('buy')">{{ translateTitle("买") }}&{{ translateTitle("卖") }}</el-button> -->
					<el-button v-if="tableActive == '1'" @click="changeTable(2)">{{ translateTitle("交易") }}</el-button>
					<el-button v-else @click="changeTable(1)">{{ translateTitle("钱包") }}</el-button>
				</div>
			</div>
			<div v-loading="tableLoading" class="list">
				<el-table v-if="tableActive == '1'" class="custom-table flex-table" :data="listCoin" style="width: 100%">
					<el-table-column prop="balance" :label="translateTitle('币种')" min-width="250" show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="flex">
								<img :src="scope.row.coinLogo" class="coinLogo" width="35" height="35" />
								<div>
									<div class="list-item-title">{{ scope.row.coinName }}</div>
									<div class="disal">
										<el-tooltip class="item" effect="dark" placement="top">
											<div slot="content">
												<div class="pointer">
													{{scope.row.walletAddress }}
												</div>
											</div>
											<span v-if="scope.row.walletAddress"
												class="list-item-num mr20">{{ scope.row.walletAddress }}</span>
										</el-tooltip>

										<img :src="require('@/assets/imgs/ico_copy@2x.png')" class="cp mr20" width="30"
											@click="onCopy(scope.row.walletAddress)" />
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="coinName" :label="translateTitle('金额')" min-width="180"
						show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="list-item-title">
								{{ scope.row.balance }}
							</div>
							<div class="list-item-num1">{{ scope.row.balance }}<span></span></div>
						</template>
					</el-table-column>
					<el-table-column :label="translateTitle('操作')" width="280">
						<template slot-scope="scope">
							<el-button style="color: #786234;" @click="handleClickBtn('send',scope.row, 'first')" type="text" size="small"><img
									width="20"
									src="../../assets/imgs/ico_jiami_send@2x.png">{{translateTitle('发送')}}</el-button>
							<el-button  style="color: #786234;" type="text" @click="handleClickBtn('send',scope.row, 'second')" size="small"><img
									width="20"
									src="../../assets/imgs/ico_jiami_receive@2x.png">{{translateTitle('接收')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-table v-if="tableActive == '2'" stripe class="custom-table flex-table" :data="list" style="width: 100%">
					<el-table-column prop="businessId" :label="translateTitle('交易id')" min-width="160">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" placement="top">
								<div slot="content">
									<div class="pointer">
										{{scope.row.businessId}}
									</div>
								</div>
								<span v-if="scope.row.businessId"
									class="list-item-num mr20">{{ scope.row.businessId | mobileFilter }}</span>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="walletAddress" :label="translateTitle('发送地址')" min-width="150"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="sourceWalletAddress" :label="translateTitle('接收地址')" min-width="150"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="coinName" :label="translateTitle('类型')" min-width="80" show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="">
								<span v-if="scope.row.transactionType == '1'">{{translateTitle('转出')}}</span>
								<span v-else-if="scope.row.transactionType == '2'">{{translateTitle('买币')}}</span>
								<span v-else-if="scope.row.transactionType == '3'">{{translateTitle('卖币')}}</span>
								<span v-else-if="scope.row.transactionType == '4'">{{translateTitle('提现')}}</span>
								<span v-else-if="scope.row.transactionType == '5'">{{translateTitle('充值')}}</span>
								<span v-else-if="scope.row.transactionType == '6'">{{translateTitle('消费')}}</span>
								<span v-else-if="scope.row.transactionType == '7'">{{translateTitle('转入')}}</span>
								<span v-else-if="scope.row.transactionType == '9'">{{translateTitle('服务费')}}</span>
								<span v-else-if="scope.row.transactionType == '10'">{{translateTitle('充值银行')}}</span>
								<span v-else-if="scope.row.transactionType == '11'">{{translateTitle('充值加密货币')}}</span>
								<span v-else-if="scope.row.transactionType == '12'">{{translateTitle('托管')}}</span>
								<span v-else-if="scope.row.transactionType == '13'">{{translateTitle('托管赎回')}}</span>
								<span v-else-if="scope.row.transactionType == '14'">{{translateTitle('直推奖励')}}</span>
								<span v-else-if="scope.row.transactionType == '15'">{{translateTitle('平级奖励')}}</span>
								<span v-else-if="scope.row.transactionType == '16'">{{translateTitle('奖金分红')}}</span>
								<span v-else-if="scope.row.transactionType == '18'">{{translateTitle('贷款保证金')}}</span>
								<span v-else-if="scope.row.transactionType == '19'">{{translateTitle('贷款')}}</span>
								<span v-else-if="scope.row.transactionType == '20'">{{translateTitle('还款')}}</span>
								<span v-else-if="scope.row.transactionType == '21'">{{translateTitle('退还保证金')}}</span>
								<span v-else-if="scope.row.transactionType == '22'">{{translateTitle('复利收益')}}</span>
								<span v-else-if="scope.row.transactionType == '23'">{{translateTitle('托管收益')}}</span>
								<span v-else-if="scope.row.transactionType == '24'">{{translateTitle('团队奖励')}}</span>
								<span v-else-if="scope.row.transactionType == '25'">{{translateTitle('越级奖励')}}</span>
								<span v-else-if="scope.row.transactionType == '26'">{{translateTitle('闪兑')}}</span>
								<span v-else-if="scope.row.transactionType == '27'">{{translateTitle('手续费分红')}}</span>
								<span v-else-if="scope.row.transactionType == '28'">{{translateTitle('生态池分红')}}</span>
								<span v-else-if="scope.row.transactionType == '28'">{{translateTitle('节点赠送')}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="coinName" :label="translateTitle('币种')" min-width="80"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="balance" :label="translateTitle('金额')" min-width="80"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="coinName" :label="translateTitle('状态')">
						<template slot-scope="scope">
							<div v-if="scope.row.businessStatus == '0'" class="info-color">
								{{ translateTitle('待处理')}}
							</div>
							<div v-else-if="scope.row.businessStatus == '1'" class="success-color">
								{{translateTitle('处理成功')}}
							</div>
							<div v-else class="danger-color">
								{{ translateTitle('处理失败') }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="datesStr" :label="translateTitle('交易时间')" min-width="120"
						show-overflow-tooltip></el-table-column>
					<el-table-column :label="translateTitle('操作')" width="120">
						<template slot-scope="scope">
							<el-button @click="handledetail(scope.row)" type="text" style="color: #786234;"
								size="small">{{translateTitle('详情')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<Pagination :page.sync="page" :limit.sync="pageSize" :total="total" @pagination="changeData" />
				<div class="noData" v-if="list.length == 0&&!tableLoading">
					{{ translateTitle("暂无数据") }}
				</div>
			</div>
		</el-card>
		<!-- 二维码预览 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div style="text-align: center">
				<vue-qr v-if="codeText" :size="250" :text="codeText" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">{{
          translateTitle("确定")
        }}</el-button>
			</span>
		</el-dialog>
		<!-- 详情 -->
		<DialogPop width="700px" :title="translateTitle('详情')" :visible="visibleDetail" :closeBtn="false"
			:sureBtn="false" @onClose="onCloseDetail">
			<el-descriptions class="change-descriptions" title="" :column="1">
				<el-descriptions-item label="Bank ID">{{ detailData.id }}</el-descriptions-item>
				<el-descriptions-item l
					:label="translateTitle('请求id')">{{ detailData.requestId }}</el-descriptions-item>
				<el-descriptions-item :label="translateTitle('交易id')">{{ detailData.businessId }}</el-descriptions-item>
				<el-descriptions-item 
					:label="translateTitle('发送地址')">{{ detailData.walletAddress }}</el-descriptions-item>
				<el-descriptions-item :label="translateTitle('交易金额')"
					content-class-name="common-color"><span>{{ detailData.balance }}</span></el-descriptions-item>
				<el-descriptions-item 
					:label="translateTitle('接收地址')">{{ detailData.sourceWalletAddress }}</el-descriptions-item>
				<el-descriptions-item label="Memo"
					content-class-name="common-color"><span>{{ detailData.memo }}</span></el-descriptions-item>
				<el-descriptions-item 
					:label="translateTitle('手续费')"><span>{{ detailData.gas }}</span></el-descriptions-item>
				<el-descriptions-item :label="'Bank'+translateTitle('手续费')"
					content-class-name="common-color"><span>{{ detailData.amount }}</span></el-descriptions-item>
				<el-descriptions-item 
					:label="translateTitle('交易时间')">{{ detailData.datesStr }}</el-descriptions-item>
				<el-descriptions-item :label="translateTitle('交易状态')"
					:content-class-name="detailData.businessStatus == '0'? 'info-color': detailData.businessStatus == '1'?'success-color':'danger-color'">{{ detailData.businessStatus == '0'? translateTitle('待处理'): detailData.businessStatus == '1'?translateTitle('处理成功'):translateTitle('处理失败')}}</el-descriptions-item>
			</el-descriptions>
		</DialogPop>
		<!-- 发送接收弹窗 -->
		<SendDiaolg v-if="sendVisible" v-model="sendVisible" :sendData="sendData" :sendType="sendType"
			@visibleEvent="visibleEvent" />
		<!-- 添加钱包 -->
		<AddMoneyDiaolg v-if="addMoneyVisible" v-model="addMoneyVisible" @visibleEvent="visibleEvent" />
		<!-- 买和卖 -->
		<BuyDiaolg v-if="buyVisible" v-model="buyVisible" @visibleEvent="visibleEvent" />
		<!-- 转账 -->
		<TransferDiaolg v-if="transfervisible" v-model="transfervisible" @visibleEvent="visibleEvent" />
		<!-- 充值弹窗 -->
		<RechargeDiaolg v-if="rechargeVisible" v-model="rechargeVisible" @visibleEvent="visibleEvent" />
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		coinNames,
		getBalanceCoinNam,
		userBankBalance,
		totalUsdtBalance,
		accountGetBalance,
		coinLink,
	} from "@/api/index";
	// import Headers from "../../components/accountHeader/currencyIndex";
	import SendDiaolg from "./components/sendDiaolg";
	import AddMoneyDiaolg from "./components/addMoneyDiaolg";
	import BuyDiaolg from "./components/buyDiaolg";
	import TransferDiaolg from "./components/transfer"
	import RechargeDiaolg from "./components/rechargeDiaolg.vue";
	import local from "@/utils/local";
	import {
		getBalanceCoinName
	} from "@/api/account.js";
	import {
		cryptoStatement
	} from "@/api/accountManage.js";
	import {
		cryptoCoinList
	} from "@/api/wallet.js";
	import VueQr from "vue-qr";
	import {
		translateTitle
	} from "@/utils/i18n";
	// import { format_d } from 'echarts/types/dist/shared';
	export default {
		components: {
			SendDiaolg,
			AddMoneyDiaolg,
			BuyDiaolg,
			VueQr,
			TransferDiaolg,
			RechargeDiaolg
		},
		filters: {
			mobileFilter(val) {
				let reg = /^(.{9}).*(.{6})$/
				return val.replace(reg, '$1......$2')
			},
		},
		data() {
			return {
				balanceCoin: "0",
				headerImg: require("@/assets/imgs/ico_jiamibizhong@2x.png"),
				list: [
					// { name: "BTC", num: "2233333343333333333333333333", money: "233" },
					// { name: "BTC", num: "223333334", money: "233" },
					// { name: "BTC", num: "223333334", money: "233" },
					// { name: "BTC", num: "223333334", money: "233" },
				],
				tableLoading: false,
				sendVisible: false,
				addMoneyVisible: false,
				BalanceCoinNam: "0",
				BalanceCoinNam1: "0",
				buyVisible: false,
				dialogVisible: false,
				rechargeVisible: false,
				tableActive: '1',
				coinName: "",
				pageSize: 10,
				codeText: "",
				page: 1,
				total: 0,
				option: [],
				choiceCoin: {
					coinName: '',
					coinLogo: "",
					id: '',
				},
				UsdtBalance: "0",
				bankBalance: "0",
				transfervisible: false,
				visibleDetail: false,
				sendType: 'first',
				sendData: '',
				detailData: '',
				globalLoading: null,
				aa: '',
				listCoin:[]
			};
		},
		computed: {
			...mapState({
				user: (state) => state.user.user,
			}),
		},
		created() {
			// this.coinName = local.get("coinName");

			// this.getTableData();
			this.getBalanceCoin();
			// this.getBankBalance()
			this.getData();
		},
		methods: {
			translateTitle,
			getBalanceCoin() {
				totalUsdtBalance().then((res) => {
					if (res.code == 200) {
						this.balanceCoin = res.data.amount;
					} else {
						this.$message.error(this.translateTitle(res.msg));
					}

				});
			},
			checkImg(item) {
				this.dialogVisible = true;
				this.codeText = item;
			},
			handleClose() {
				this.dialogVisible = false;
			},
			// changeType(data) {
			//   console.log(data)
			//   let obj = this.option.find(item => item.id === data)

			//   this.choiceCoin.coinName = obj.coinName;
			//   this.choiceCoin.coinLogo = obj.coinLogo;
			//   this.choiceCoin.id = obj.id;
			//   local.set("coinName", this.choiceCoin);
			//   this.globalLoading = this.$loading({
			//     lock: true,
			//     text: '',
			//     spinner: '',
			//     background: 'rgba(255, 255, 255, 0.8)'
			//   });
			//   this.getAccountBalanceCoin(obj, 1);
			// },
			onCopy(text) {
				if (navigator.clipboard) {
					// clipboard api 复制
					navigator.clipboard.writeText(text);
				} else {
					const textarea = document.createElement("textarea");
					document.body.appendChild(textarea);
					// 隐藏此输入框
					textarea.style.position = "fixed";
					textarea.style.clip = "rect(0 0 0 0)";
					textarea.style.top = "10px";
					// 赋值
					textarea.value = text;
					// 选中
					textarea.select();
					// 复制
					document.execCommand("copy", true);
					// 移除输入框
					document.body.removeChild(textarea);
				}
				this.$message.success(this.translateTitle("内容已复制到剪贴板"));
			},
			changeTable(type) {
				this.list = []
				this.listCoin = []
				this.tableActive = type
				if (type == '1') {
					this.getData()
				} else {
					this.getcryptoStatement()
				}
			},
			changeData() {
				// this.page = 0
				if (this.tableActive == '1') {
					this.getData()
				} else {
					this.getcryptoStatement()
				}
			},
			getData() {
				const params = {
					pageSize: this.pageSize,
					page: this.page,
					baseUserId: "",
				};
				this.globalLoading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				cryptoCoinList(params).then((res) => {
					if (res.code == 200) {
						this.listCoin = res.data.records;
						this.total = res.data.total;
						this.globalLoading.close()
					} else {
						this.$message.error(this.translateTitle(res.msg));
					}

				});
			},
			async getcryptoStatement() {
				let params = {
					page: this.page,
					pageSize: this.pageSize,
				};
				this.globalLoading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				let res = await cryptoStatement(params);
				if (res.code == 200) {
					this.list = res.data.records;
					this.globalLoading.close()
					this.total = res.data.total;
				}

			},
			visibleEvent() {
				this.sendVisible = false
				this.addMoneyVisible = false;
				this.buyVisible = false;
				this.rechargeVisible = false;
				// this.getData();
				this.getcryptoStatement()
			},
			//type add:添加钱包 buy:买&卖 send:发送&接收
			handleClickBtn(type, row, type1) {
				console.log(type)
				if (type == "add") {
					this.addMoneyVisible = true;
				} else if (type == "buy") {
					this.buyVisible = true;
				} else if (type == "send") {
					this.sendType = type1
					this.sendData = row
					this.sendVisible = true;
				} else if (type == 'recharge') {
					this.rechargeVisible = true;
				}
			},
			getAccountBalanceCoin(val, type) {
				if (type == "1") {
					let params = {
						coinName: val.coinName,
						coinLink: val.coinLink,
					}
					accountGetBalance(params).then((res) => {
						if (res.code == 200) {
							this.BalanceCoinNam = res.data.amount;
							this.globalLoading.close()
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}

					});
				}
			},
			async getTableData() {
				let res = await coinNames();
				this.option = res.data
				this.choiceCoin.coinName = this.option[0].coinName;
				this.choiceCoin.coinLogo = this.option[0].coinLogo;
				this.choiceCoin.id = this.option[0].id;
				local.set("coinName", this.choiceCoin);
				this.getAccountBalanceCoin(this.option[0], 1);
			},
			getBankBalance(val) {
				this.getUserBankBalance();
				this.getTotalUsdtBalance();
			},
			getUserBankBalance() {
				userBankBalance().then((res) => {
					if (res.code == 200) {
						this.bankBalance = res.data.amount;
					} else {
						this.$message.error(this.translateTitle(res.msg));
					}
				});
			},
			getTotalUsdtBalance() {
				totalUsdtBalance().then((res) => {
					if (res.code == 200) {
						this.UsdtBalance = res.data.amount;
					} else {
						this.$message.error(this.translateTitle(res.msg));
					}
				});
			},
			transfer() {
				this.transfervisible = true;
			},
			handledetail(data) {
				this.detailData = data
				this.visibleDetail = true
			},
			onCloseDetail() {
				this.visibleDetail = false
			},
		},
	};
</script>

<style lang="less" scoped>
	.title {
		font-size: 26px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-bottom: 20px;
		text-align: right;
	}

	.top-header {
		padding: 10px 0 10px 0;
		display: flex;
		align-items: center;

		.header-left {
			width: 30%;

			img {
				width: 18%;
			}

			.header-left-title {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #31363E;
				line-height: 16px;
				-webkit-background-clip: text;
				margin-left: 10px;
			}

			.header-left-num {
				font-size: 24px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				color: #31363E;
				line-height: 16px;

				span {
					font-weight: 400;
					font-size: 13px;
				}
			}
		}

		::v-deep {
			.btn-list {
				flex: 1;
				text-align: right;

				.el-button--medium {
					width: 26%;
					height: 66px;
					background: #F7F6F5;
					border: 1px solid #F7F6F5;
					border-radius: 17px;
					font-size: 16px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: 400;
					color: #353a42;
				}
			}
		}
	}

	.list {
		margin-top: 20px;
		min-height: 300px;

		.list-item-title {
			font-size: 18px !important;
			font-weight: bold !important;
			color: #31363E !important;
			margin-bottom: 8px;
			margin-top: 6px;
		}

		.list-item-num {
			font-size: 14px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			line-height: 21px;
			-webkit-background-clip: text;
		}

		.list-item-num1 {
			font-size: 14px;
		}

		.noData {
			text-align: center;
			line-height: 60px;
			color: #909399;
		}
	}

	.account-content {
		width: 100%;
		background-image: url(../../assets/imgs/newImg/cardBg.png);
		border-radius: 16px;
		padding: 30px 30px;
		background-repeat: no-repeat;
		background-size: 100%;
		padding: 30px 30px;
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.all-amount {
			font-size: 16px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
		}

		.all-num {
			font-size: 32px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			font-weight: 700;
			color: #FFFFFF;
			margin: 15px 0px 0px 0px;
   display: flex;
	    align-items: baseline;
			img {
				margin-right: 5px;
			}

			span {
				font-weight: 700;
				color: #FFFFFF;
				font-size: 14px;
				margin-left: 5px;
			}
		}

		.btn {
			width: 20%;
			cursor: pointer;
			height: 60px;
			background: #FFFFFF;
			border-radius: 12px;
			text-align: center;
			line-height: 60px;
			font-size: 18px;
			font-weight: 400;
			color: #31363E;
		}

		.account-center {
			.title {
				font-size: 14px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #8da0ad;
			}

			.amount {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: bold;
				color: #31363e;
				padding-top: 5px;
			}
		}
	}

	::v-deep {
		.select-item {
			margin-left: 10px;
		}
	}
</style>