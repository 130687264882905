<template>
  <div class="account-content">
    <DialogPop
      width="700px"
      :title="translateTitle('转账方式')"
      :visible="visible"
      :closeBtn="false"
      :btnloading="btnloading"
      :sureText="translateTitle('转账')"
      @onClose="onClose"
      @onSure="onSure('ruleForm')"
    >
      <div class="custum-dialog type">
        <div
          class="img"
          :class="isCheck == '1' ? 'active' : ''"
          @click="checkType(1)"
        >
          <img :src="img4" class="mb15" width="120" />
          <p>{{ translateTitle("银行账户") }}</p>
        </div>
        <div
          class="img"
          :class="isCheck == '2' ? 'active' : ''"
          @click="checkType(2)"
        >
          <img :src="img5" class="mb15" width="120" />
          <p>{{ translateTitle("加密账户") }}</p>
        </div>
      </div>
      <div class="change-form">
        <el-form
          label-position="top"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <el-form-item :label="translateTitle('数量')" prop="amount">
            <el-input
              :placeholder="translateTitle('请输入数量')"
              size="large"
              v-model="ruleForm.amount"
              class="input-with-select"
            >
              <el-select
              class="svg-container"
                v-if="isCheck == '2'"
                v-model="ruleForm.coinName"
                slot="append"
                :placeholder="translateTitle('请选择')"
                @change="changeCoinName1"
              >
                <template #prefix>
            <span class="flex">
              <img
                :src="choiceCoin.coinLogo"
                width="24"
                height="24"
              />
            </span>
          </template>
                <el-option
                  v-for="(item, index) in option"
                  :key="item.coinName"
                  :label="item.coinName"
                  :value="item.coinName"
                >
                   <div class="disal">
              <img
                :src="item.coinLogo"
                width="24"
                height="24"
              />
              <span class="select-item">{{ item.coinName }}</span>
            </div>
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="">
            <div class="line3">
              <span class="left">{{ translateTitle("可用余额") }}</span>
              <span class="right"
                >＄{{ isCheck == "1" ? bankBalance : BalanceCoinNam1 }}</span
              >
            </div>
            <div class="line3">
              <span class="left">{{ translateTitle("实际到账") }}</span>
              <span class="right"
                >＄{{ ruleForm.amount || 0 }}</span
              >
            </div>
          </el-form-item>
          <el-form-item
            v-if="isCheck == '2'"
            :label="translateTitle('提币网络')"
            prop="coinNetWork"
          >
            <el-select
              class="svg-container"
              style="width: 100%"
              v-model="ruleForm.coinLink"
              :placeholder="translateTitle('请选择')"
              @change="changeCoinLink"
            >
              <template #prefix>
            <span class="flex">
              <img
                :src="require('@/assets/imgs/icon_btc.png')"
                width="24"
                height="24"
              />
            </span>
          </template>
              <el-option
                v-for="item in CoinLinkOption"
                :key="item.coinLink"
                :label="item.coinLink"
                :value="item.coinLink"
              >
                <div class="disal">
                  <img
                :src="require('@/assets/imgs/icon_btc.png')"
                width="24"
                height="24"
              />
                  <span class="select-item">{{ item.coinLink }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="translateTitle('邮箱')" prop="email">
            <el-input
              size="large"
              :placeholder="translateTitle('输入收件人邮箱')"
              v-model="ruleForm.email"
              clearable
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </DialogPop>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  coinNames,
  getBalanceCoinNam,
  userBankBalance,
  coinBalanceUSD,
  coinLink,
} from "@/api/index";
// import Headers from "../../components/accountHeader/index.vue";
import { coin_transfer, bank_transfer } from "@/api/accountManage";
import { translateTitle } from "@/utils/i18n";

import local from "@/utils/local";
export default {
  name: "transferDiaolg",
  model: {
    prop: "visible",
    event: "visibleEvent",
  },
  props: {
    // 显示/隐藏
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value1: false,
      img4: require("@/assets/imgs/icon_yinhangzhanghu @2x.png"),
      img5: require("@/assets/imgs/icon_jiamizhanghu @2x.png"),
      choiceCoin: {
        coinName: '',
        coinLogo: "",
      },
      total: 20,
      size: 10,
      option: [],
      BalanceCoinNam: "0",
      BalanceCoinNam1: "0",
      pageSize: 10,
      page: 1,
      btnloading: false,
      isCheck: "1",
      ruleForm: {
        email: "",
        amount: "",
        coinName: "",
        coinLink: "",
      },
      rules: {
        amount: [
          {
            required: true,
            message: this.translateTitle("请输入数量"),
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: this.translateTitle("请输入邮箱"),
            trigger: "change",
          },
        ],
      },
      BalanceCoinNam: "",
      bankBalance: "0",
      CoinLinkOption: [],
    };
  },
  created() {
    this.getTableData();
    this.getBankBalance()
  },
  methods: {
    translateTitle,
    ...mapActions({
      changeCoinName: "settings/changeCoinName",
    }),
    async getTableData() {
      let res = await coinNames();
      this.option = res.data

      this.choiceCoin.coinName = this.option[0].coinName;
      this.choiceCoin.coinLogo = this.option[0].coinLogo;
      this.ruleForm.coinName = this.option[0].coinName;
      this.changeCoinName(this.option[0].coinName);
      local.set("coinName", this.choiceCoin);
    },
    async getCoinLink(val) {
      let res = await coinLink(val);
      this.CoinLinkOption = res.data;
    },
    changeCoinLink() {
      this.getBalanceCoin("", 2);
    },
    getBalanceCoin(val, type) {
      if (type == "1") {
        getBalanceCoinNam(val).then((res) => {
          if(res.code == 200) {
            this.BalanceCoinNam = res.data.amount;
          } else {
            this.$message.error(this.translateTitle(res.msg));
          }
          
        });
      } else {
        if (this.ruleForm.coinName && this.ruleForm.coinLink) {
          let params = {
            coinName: this.ruleForm.coinName,
            coinLink: this.ruleForm.coinLink,
          };
          coinBalanceUSD(params).then((res) => {
            if(res.code == 200) {
              this.BalanceCoinNam1 = res.data.balance;
            } else {
              this.$message.error(this.translateTitle(res.msg));
            }
          });
        }
      }
    },
    transfer() {
      this.visible = true;
    },
    getBankBalance(val) {
      this.getUserBankBalance();
    },
    getUserBankBalance() {
      userBankBalance().then((res) => {
        if(res.code == 200) {
          this.bankBalance = res.data.amount;
        } else {
          this.$message.error(this.translateTitle(res.msg));
        }
      });
    },
    checkType(val) {
      this.isCheck = val;
      if (val == "1") {
        //
      } else {
        this.getCoinLink(this.ruleForm.coinName);
      }
    },
    changeCoinName1(data) {
      let obj  = this.option.find(item => item.coinName === data)
      this.choiceCoin.coinName = obj.coinName;
      this.choiceCoin.coinLogo = obj.coinLogo;
      this.ruleForm.coinName = obj.coinName;
      this.ruleForm.coinLink = "";
      this.getCoinLink(this.ruleForm.coinName);
      this.getBalanceCoin(this.ruleForm.coinName, 2);
    },
    onSure(formName) {
      if (this.isCheck == "") {
        return this.$message.error(this.translateTitle("请先选择转账方式"));
      } else {
        let _resquest;
        let params;
        if (this.isCheck == "1") {
          _resquest = bank_transfer;
          params = {
            amount: this.ruleForm.amount,
            email: this.ruleForm.email,
          };
        } else {
          _resquest = coin_transfer;
          params = {
            amount: this.ruleForm.amount,
            email: this.ruleForm.email,
            coinName: this.ruleForm.coinName,
            coinLink: this.ruleForm.coinLink,
          };
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.btnloading = true
            _resquest(params).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.translateTitle("操作成功"));
                // this.getContact()
                this.$refs["ruleForm"].resetFields();
                this.$emit("visibleEvent", false);
              } else {
                 this.$message.error(this.translateTitle(res.msg));
              }
            }).finally(() => {
              this.btnloading = false
            });
          } else {
            return false;
          }
        });
      }
    },
    onClose() {
      this.$emit("visibleEvent", false);
    },
  },
};
</script>

<style lang="less" scoped>
.account-content {
  width: 100%;
  background: #f2f7f9;
  border-radius: 17px;
  padding: 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .all-amount {
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #31363e;
  }
  .all-num {
    font-size: 48px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 800;
    color: #31363e;
    margin-top: 15px;
  }
  .btn {
    width: 28%;
    height: 66px;
    background: #353a42;
    border-radius: 17px;
    color: #fff;
    text-align: center;
    line-height: 66px;
    font-size: 19px;
  }
  .account-center {
    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #8da0ad;
    }
    .amount {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: bold;
      color: #31363e;
      padding-top: 5px;
    }
  }
}
::v-deep {
  .custum-dialog {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .img {
      text-align: center;
      background: #f2f7f9;
      border-radius: 10px;
      // padding: 30px 30px 20px 30px;
      padding: 20px 0;
      width: 46%;
      border: 2px solid #f2f7f9;
      p {
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: bold;
        color: #24282e;
      }
      // img {
      //   width: 60%;
      // }
    }
    .img.active {
      border: 2px solid #f6d655;
    }
  }
}
.change-form {
  background: #f2f7f9;
  padding: 30px;
  border-radius: 10px;

  ::v-deep {
    .el-input__inner {
      height: 50px !important;
      line-height: 50px !important;
      border: none !important;
    }
    .el-form-item__label {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: bold;
      color: #b9bfca;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #06a43e !important;
      border-color: #06a43e !important;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #000;
    }
    .el-checkbox__inner:hover {
      border-color: #000;
    }
    .input-with-select .el-input-group__append {
      background-color: #fff;
      border: none;
      width: 110px;
    }
  }
}
.select-item {
  margin-left: 10px;
}
</style>
